import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux'
import {createStore} from 'redux'
import fivePeLoApp from "./redux/reducers";
import {myToday} from "./utils";
import moment from "moment";

const weekNums = fetchMockWeekNums();

const mockTraining = (weekStartDate, trainings) => {
  return trainings.map((training, index) => ({day: dateFromWeekAndDayNumber(weekStartDate, index + 1), training}))
}
function fetchMockWeekNums() {
  return [-1, 0, 1, 2, 3, 4, 5].map(num => {
    return {
      startDate: myToday().add(num * 7, 'days').startOf('isoWeek').format('YYYY-MM-DD'),
      endDate: myToday().add(num * 7, 'days').endOf('isoWeek').format('YYYY-MM-DD')
    }
  });
}

function dateFromWeekAndDayNumber(weekStartDate, dayNumber) {
  return moment(weekStartDate).add(dayNumber - 1, 'days').format('ddd DD/M')
}

const initialState = {
  currentWeek: 1,
  period: {
    id: '19',
    weeks: [
      {
        countInPeriod: 1,
        startDate: weekNums[0].startDate,
        endDate: weekNums[0].endDate,
        participants:
          [
            {
              name: "Lotta (green)",
              percentage: 11,
              training:
                mockTraining(weekNums[0].startDate, ['Löpning', 'Styrkelyft med PT', 'Intervaller med klubben (2 k uppv, löpskola, 3*7 min @ 4,50)', 'Military Training 75 min', 'Trail med LillP Kärsön ca 6km', 'PT "disco fredag" aka bodybuilding', 'PT "disco fredag" aka bodybuilding'])
            },
            {
              name: "Peter (red)",
              percentage: 22,
              training: mockTraining(weekNums[0].startDate, [ "35 min mc", '', '45 min yoga', '', 'Kärrsön m Lotta', 'Tennis', '' ] )
            },
            {
              name: "David (red)",
              percentage: 22,
              training: mockTraining(weekNums[0].startDate,[ "", '', '45 min yoga', '', 'Kärrsön m Lotta', 'Tennis', '' ])
            }
          ]
      },
      {
        countInPeriod: 2,
        startDate: weekNums[1].startDate,
        endDate: weekNums[1].endDate,
        participants:
          [
            {
              name: "Lotta (green)",
              percentage: 11,
              training:
                mockTraining(weekNums[1].startDate, [ 'Löpning', ' Styrkelyft med PT ', ' Intervaller med klubben (2 k uppv, löps,kola, 3*7 min @ 4,50) ', ' Military Training 75 min ', 'Löpning', '', '' ])
            },
            {
              name: "Peter (olive)",
              percentage: 22,
              training:
                mockTraining(weekNums[1].startDate, [ "35 min mc", '30 min löp', '45 min yoga', '', 'Löpning', '', '' ])
            },
            {
              name: "David (red)",
              percentage: 22,
              training:
                mockTraining(weekNums[1].startDate, [ "", '', '', 'Löpning', '', '', '' ]) },
            {
              name: "Johanna (yellow)",
              percentage: 22,
              training: mockTraining(weekNums[1].startDate,  [ "", '', 'Yoga', 'Pamela', '', '', '' ] ) ,
            }
          ]
      },
      {
        countInPeriod: 3,
        startDate: weekNums[2].startDate,
        endDate: weekNums[2].endDate,
        participants:
          [
            {
              name: "Lotta",
              percentage: 0,
              training: [{day: 'Mån 20/4', training: ''},
                {day: 'Tis 21/4', training: ''},
                {day: 'Ons 22/4', training: ''},
                {day: 'Tor 23/4', training: ''},
                {day: 'Fre 24/4', training: ''},
                {day: 'Lör 25/4', training: ''},
                {day: 'Sön 26/4', training: ''}]

            },
            {
              name: "Peter",
              percentage: 0,
              training: [{day: 'Mån 20/4', training: ''},
                {day: 'Tis 21/4', training: ''},
                {day: 'Ons 22/4', training: ''},
                {day: 'Tor 23/4', training: ''},
                {day: 'Fre 24/4', training: ''},
                {day: 'Lör 25/4', training: ''},
                {day: 'Sön 26/4', training: ''}]

            }
          ]
      },
      {
        countInPeriod: 4,
        startDate: weekNums[3].startDate,
        endDate: weekNums[3].endDate,
        participants:
          [
            {
              name: "Lotta",
              percentage: 0,
              training: [{day: 'Mån 20/4', training: ''},
                {day: 'Tis 21/4', training: ''},
                {day: 'Ons 22/4', training: ''},
                {day: 'Tor 23/4', training: ''},
                {day: 'Fre 24/4', training: ''},
                {day: 'Lör 25/4', training: ''},
                {day: 'Sön 26/4', training: ''}]

            },
            {
              name: "Peter",
              percentage: 0,
              training: [{day: 'Mån 20/4', training: ''},
                {day: 'Tis 21/4', training: ''},
                {day: 'Ons 22/4', training: ''},
                {day: 'Tor 23/4', training: ''},
                {day: 'Fre 24/4', training: ''},
                {day: 'Lör 25/4', training: ''},
                {day: 'Sön 26/4', training: ''}]

            }
          ]
      },
      {
        countInPeriod: 5,
        startDate: weekNums[4].startDate,
        endDate: weekNums[4].endDate,
        participants:
          [
            {
              name: "Lotta",
              percentage: 0,
              training: [{day: 'Mån 20/4', training: ''},
                {day: 'Tis 21/4', training: ''},
                {day: 'Ons 22/4', training: ''},
                {day: 'Tor 23/4', training: ''},
                {day: 'Fre 24/4', training: ''},
                {day: 'Lör 25/4', training: ''},
                {day: 'Sön 26/4', training: ''}]

            },
            {
              name: "Peter",
              percentage: 0,
              training: [{day: 'Mån 20/4', training: ''},
                {day: 'Tis 21/4', training: ''},
                {day: 'Ons 22/4', training: ''},
                {day: 'Tor 23/4', training: ''},
                {day: 'Fre 24/4', training: ''},
                {day: 'Lör 25/4', training: ''},
                {day: 'Sön 26/4', training: ''}]

            }
          ]
      },
      {
        countInPeriod: 6,
        startDate: weekNums[6].startDate,
        endDate: weekNums[6].endDate,
        participants:
          [
            {
              name: "Lotta",
              percentage: 0,
              training: [{day: 'Mån 20/4', training: ''},
                {day: 'Tis 21/4', training: ''},
                {day: 'Ons 22/4', training: ''},
                {day: 'Tor 23/4', training: ''},
                {day: 'Fre 24/4', training: ''},
                {day: 'Lör 25/4', training: ''},
                {day: 'Sön 26/4', training: ''}]

            },
            {
              name: "Peter",
              percentage: 0,
              training: [{day: 'Mån 20/4', training: ''},
                {day: 'Tis 21/4', training: ''},
                {day: 'Ons 22/4', training: ''},
                {day: 'Tor 23/4', training: ''},
                {day: 'Fre 24/4', training: ''},
                {day: 'Lör 25/4', training: ''},
                {day: 'Sön 26/4', training: ''}]

            }
          ]
      },
      {
        countInPeriod: 7,
        startDate: weekNums[6].startDate,
        endDate: weekNums[6].endDate,
        participants:
          [
            {
              name: "Lotta",
              percentage: 0,
              training: [{day: 'Mån 20/4', training: ''},
                {day: 'Tis 21/4', training: ''},
                {day: 'Ons 22/4', training: ''},
                {day: 'Tor 23/4', training: ''},
                {day: 'Fre 24/4', training: ''},
                {day: 'Lör 25/4', training: ''},
                {day: 'Sön 26/4', training: ''}]

            },
            {
              name: "Peter",
              percentage: 0,
              training: [{day: 'Mån 20/4', training: ''},
                {day: 'Tis 21/4', training: ''},
                {day: 'Ons 22/4', training: ''},
                {day: 'Tor 23/4', training: ''},
                {day: 'Fre 24/4', training: ''},
                {day: 'Lör 25/4', training: ''},
                {day: 'Sön 26/4', training: ''}]

            }
          ]
      }
    ]
  }
}
console.log(initialState)
const store = createStore(fivePeLoApp, initialState);
// store.subscribe(() => console.log(store.getState()))

ReactDOM.render(<Provider store={store}><App/></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
