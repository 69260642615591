import moment from "moment";

//TODO: put mock stuff, eg  mytoday somewhere else
export function isCurrentWeek(week) {
  return myToday().isBetween(moment(week.startDate), moment(week.endDate), null, '[]');
}

export function isFutureWeek(week) {
  return moment(week.startDate).isAfter(myToday());
}


export function getTrainingStats(week, training) {
  const trainingDaysPerWeek = 5; //TODO: put in state

  const isPresentWeek = isCurrentWeek(week);
  const completedDays = (training.filter(trainingDay => trainingDay.training !== '')).length;


  //>= 100 %: grön
  //< 100 % och minst en vilodag kvar: grön
  //< 100% och ingen vilodag kvar men dagar kvar = (antal dagar tränade)...

  var color = 'olive';
  var percentage = completedDays / trainingDaysPerWeek * 100;

  if (isPresentWeek) {

    const indexOfToday = training.findIndex(trainingDay => {
      return trainingDay.day === myToday().format('ddd DD/M')
    });

    const numberOfUnusedTrainingDaysLeftInWeek = training.slice(indexOfToday)
      .filter(trainingDay => trainingDay.training === '').length


    if (completedDays >= trainingDaysPerWeek) {
      color = 'green'
    } else {
      if (numberOfUnusedTrainingDaysLeftInWeek === (trainingDaysPerWeek - completedDays)) {
        color = 'yellow'
      } else if (numberOfUnusedTrainingDaysLeftInWeek < (trainingDaysPerWeek - completedDays)) {
        color = 'red'
      }
    }

  } else {
    if (isFutureWeek(week)) {
      color = 'gray'
      percentage = 0
    } else if (completedDays >= trainingDaysPerWeek) {
      color = 'green'
    } else {
      color = 'red'
    }
  }



  return {percentage, color};
}


export function myToday() {
  return moment('2020-04-24');
}


