import React from 'react';
import {Grid, Progress, Popup} from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import {Table, List, Label} from 'semantic-ui-react'
import {connect} from 'react-redux'

import {switchWeek} from "./redux/actions";
import {isCurrentWeek, getTrainingStats} from "./utils";

const mapStateToProps = (state) => {
  const currentWeekNumber = state.currentWeek;
  const find = state.period.weeks.find(week => currentWeekNumber === week.countInPeriod);
  return {
    week: find,
    weeks: state.period.weeks
  };
}

const mapDispatcherTo = (dispatch) => {
  return {
    onWeekSelect: number => dispatch(switchWeek(number))
  }
}

function range(start, end) {
  return [...Array(1 + end - start).keys()].map(v => start + v)
}

function App() {
  const WeekList = ({onWeekSelect, selectedWeek, allWeeks}) => {
    return (
      <>
        <List horizontal link>
          {allWeeks.map(week => {
            const currentWeek = isCurrentWeek(week);

            return (<List.Item as='a' onClick={() => onWeekSelect(week.countInPeriod)}>


                {selectedWeek === week.countInPeriod
                  ? <Label circular color="blue">
                    {currentWeek ? <u>{week.countInPeriod}</u> : <div>{week.countInPeriod}</div>}
                  </Label>
                  :
                  <Label circular>
                    {currentWeek ? <u>{week.countInPeriod}</u> : <div>{week.countInPeriod}</div>}
                  </Label>
                }

              </List.Item>
            )
          })}
        </List></>
    )
  }

  const StateFulStuff = ({week, weeks, onWeekSelect}) => {
    return <>
      <WeekList onWeekSelect={onWeekSelect} selectedWeek={week.countInPeriod} allWeeks={weeks}/>

      {week != null
        ? <>{week.participants.map(participante =>
          <Grid.Row>
            <ParticipantWeek week={week} name={participante.name} training={participante.training}/>
          </Grid.Row>
        )}</>
        : <div/>
      }    </>;
  }

  const ConnectedStateFulStuff = connect(mapStateToProps, mapDispatcherTo)(StateFulStuff)

  return (
    <>
      <Grid centered columns={2}>
        <Grid.Column>
          <h2 className="ui header">
            <div className="content">
              Period 1, vecka 3
              <div className="sub header">2020-01-01 - 2020-03-01</div>
            </div>
          </h2>
          <ConnectedStateFulStuff/>
        </Grid.Column>
      </Grid>
    </>);
}


const ParticipantWeek = ({week, name, training}) => {
  const {color, percentage} = getTrainingStats(week, training);


  return <>
    <div>{name}</div>
    <Popup content={
      <ParticipantWeekTable
        training={training}/>}
           trigger={<Progress percent={percentage} color={color}/>}/>
  </>;
}

const ParticipantWeekTable = ({training}) => {
  return <Table basic='very' celled collapsing>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Dag</Table.HeaderCell>
        <Table.HeaderCell>Träning</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {training.map(entry => (
        <Table.Row>
          <Table.Cell>
            {entry.day}
          </Table.Cell>
          <Table.Cell>{entry.training}</Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
}

export default App;
