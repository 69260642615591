import {SWITCH_WEEK, SET_PERIOD} from "./actions";
import {combineReducers} from 'redux'

function currentWeek(state = 1, action) {
  switch (action.type) {
    case SWITCH_WEEK:
      return action.number;
    default:
      return state
  }
}

function period(state = {}, action) {
  switch (action.type) {
    case SET_PERIOD:
      console.log('period')
      return {...state, period: action.period};
    default:
      return state
  }
}

const fivePeLoApp = combineReducers({
  currentWeek,
  period
})

export default fivePeLoApp